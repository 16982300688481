<template>
    <el-container class="page-container">
        <el-header class="page-header" style="min-height: 60px;height: auto">
            <el-form label-position='left' ref="searchForm">
                <el-row :gutter="20">
                    <el-col :xs="12" :sm="12" :md="12" :lg="6">
                        <el-form-item prop="type" label="单位" label-width="80px">
                            <el-cascader class="inner-input" :props="props" v-model="parentUnitSearch"
                                         placeholder="请选择单位"></el-cascader>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="12" :lg="6">
                        <el-form-item prop="status">
                            <el-input placeholder="请输入内容" class="inner-input" v-model="pageForm.searchKeyword"
                                      @keyup.enter.native="doSearch">
                                <el-select v-model="pageForm.searchName" slot="prepend" placeholder="查询类型"
                                           style="width: 120px;">
                                    <el-option label="用户名" value="loginname"></el-option>
                                    <el-option label="姓名" value="username"></el-option>
                                    <el-option label="手机" value="mobile"></el-option>
                                    <el-option label="邮箱" value="email"></el-option>
                                </el-select>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="12" :lg="6">
                        <el-form-item label-width="0">
                            <el-button type="primary" @click="doSearch">
                                <i class="cw-icon cw-icon-sousuo"></i>搜索
                            </el-button>
                            <!--<el-button type="primary" plain>-->
                                <!--<i class="cw-icon cw-icon-zhongzhi"></i>重置-->
                            <!--</el-button>-->
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="12" :lg="6"
                            style="display: flex;justify-content: flex-end">
                        <el-button size="medium" @click="openAdd"><i class="ti-plus"></i> 新建用户</el-button>
                        <el-dropdown @command="batchCommand" style="margin-left: 10px">
                            <el-button size="medium">
                                批量操作
                                <span class="ti-angle-down"></span>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item :command="{type:'delete'}">
                                    批量删除
                                </el-dropdown-item>
                                <el-dropdown-item :command="{type:'export'}">
                                    导出XLS
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </el-col>
                </el-row>
            </el-form>
        </el-header>
        <el-main class="page-main" style="padding: 0 20px">
            <el-table
                    border
                    :key="tableKey"
                    :data="tableData"
                    @sort-change='pageOrder'
                    size="small"
                    :default-sort="{prop: 'opAt', order: 'descending'}"
                    style="width: 100%"
                    @selection-change="handleSelectionChange"
            >
                <el-table-column
                        type="selection"
                        width="55">
                </el-table-column>
                <el-table-column type="expand">
                    <template slot-scope="scope">
                        <el-form label-position="left" inline class="el-table-expand" size="mini">
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="Email">
                                        <span>{{ scope.row.email }}</span>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="最后登录时间">
                            <span>
                                {{ formatAt(scope.row.loginAt) }}</span>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="手机号">
                                        <span>{{ scope.row.mobile }}</span>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="最后登录IP">
                                        <span>{{ scope.row.loginIp }}</span>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-form>
                    </template>
                </el-table-column>
                <el-table-column
                        sortable
                        prop="loginname"
                        label="用户名"
                >
                </el-table-column>
                <el-table-column
                        prop="username"
                        label="姓名"
                        :show-overflow-tooltip="true">
                </el-table-column>
                <el-table-column
                        prop="site.name"
                        label="关联店铺"
                        :show-overflow-tooltip="true">
                </el-table-column>
                <el-table-column
                        prop="unit"
                        label="所属单位"
                        align="center"
                        :show-overflow-tooltip="true"
                >
                    <template slot-scope="scope">
                    <span v-if="scope.row.unit!=null">
                        {{scope.row.unit.name}}
                    </span>
                    </template>
                </el-table-column>
                <el-table-column
                        sortable
                        prop="disabled"
                        label="帐号状态"
                        align="center"
                        :show-overflow-tooltip="true"
                >
                    <template slot-scope="scope">
                        <i v-if="scope.row.disabled"  class="el-icon-error Danger">禁用</i>
                        <i v-if="!scope.row.disabled" class="el-icon-success Success">启用</i>
                    </template>
                </el-table-column>
                <el-table-column
                        sortable
                        prop="userOnline"
                        label="在线状态"

                        align="center"
                        :show-overflow-tooltip="true"
                >
                    <template slot-scope="scope">
                        <i v-if="scope.row.userOnline" class="text-success ml5">在线</i>
                        <i v-if="!scope.row.userOnline" class="text-danger ml5">离线</i>
                    </template>
                </el-table-column>
                <el-table-column
                        sortable
                        prop="opAt"
                        label="操作时间"
                        align="center">
                    <template slot-scope="scope">
                        {{formatAt(scope.row.opAt)}}
                    </template>
                </el-table-column>
                <el-table-column
                        prop="userOnline"
                        label="操作"
                        width="120">
                    <template slot-scope="scope">
                        <el-dropdown @command="dropdownCommand">
                            <el-button size="mini" type="primary">
                                <i class="el-icon-setting"></i>
                                操作
                                <span class="el-icon-arrow-down"></span>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item
                                        :command="{type:'role',id:scope.row.id,loginname:scope.row.loginname}">
                                    查看权限
                                </el-dropdown-item>
                                <el-dropdown-item
                                        :command="{type:'reset',id:scope.row.id,loginname:scope.row.loginname}">
                                    重置密码
                                </el-dropdown-item>
                                <el-dropdown-item divided :command="{type:'enable',id:scope.row.id,row:scope.row}">
                                    启用
                                </el-dropdown-item>
                                <el-dropdown-item :command="{type:'disable',id:scope.row.id,row:scope.row}">
                                    禁用
                                </el-dropdown-item>
                                <el-dropdown-item divided :command="{type:'edit',id:scope.row.id}">
                                    修改
                                </el-dropdown-item>
                                <el-dropdown-item
                                        :command="{type:'delete',id:scope.row.id,loginname:scope.row.loginname}"
                                        v-if="scope.row.loginname!='superadmin'">
                                    删除
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
            </el-table>
            <br>
            <el-pagination
                    @size-change="pageSizeChange"
                    @current-change="pageNumberChange"
                    :current-page="pageForm.pageNumber"
                    :page-sizes="[10, 20, 30, 50]"
                    :page-size="pageForm.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageForm.totalCount">
            </el-pagination>
        </el-main>
        <form-dialog @close="handleClose('addForm')" title="新建用户" :dialogVisible="addDialogVisible">
            <template slot="content">
                <el-form :model="formData" ref="addForm" :rules="formRules" size="small" label-width="80px">
                    <el-form-item class="is-required" prop="parentUnit" label="所属单位" label-width="80px">
                        <el-cascader :props="props" v-model="parentUnit" placeholder="请选择单位"
                                     style="width: 100%"></el-cascader>
                    </el-form-item>
                    <el-form-item prop="loginname" label="用户名">
                        <el-input maxlength="100" placeholder="用户名"
                                  v-model="formData.loginname"
                                  auto-complete="off" tabindex="2" type="text"></el-input>
                    </el-form-item>
                    <el-form-item prop="username" label="姓名">
                        <el-input maxlength="20" placeholder="姓名"
                                  v-model="formData.username"
                                  auto-complete="off" tabindex="3" type="text"></el-input>
                    </el-form-item>
                    <el-form-item prop="password" label="登录密码">
                        <el-input maxlength="20" placeholder="登录密码" v-model="formData.password"
                                  auto-complete="off" tabindex="4" type="password"></el-input>
                    </el-form-item>
                    <el-form-item prop="passwordAgain" label="确认密码">
                        <el-input maxlength="20" placeholder="再输一次密码"
                                  v-model="formData.passwordAgain"
                                  auto-complete="off" tabindex="5" type="password"></el-input>
                    </el-form-item>
                    <el-form-item prop="siteid" label="关联店铺">
                        <el-select
                                class="inner-input"
                                v-model="formData.siteid"
                                filterable
                                remote
                                reserve-keyword
                                placeholder="请输入关联店铺名称"
                                :remote-method="remoteMethod"
                                :loading="loading">
                            <el-option
                                    v-for="item in storeOptions"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="mobile" label="手机号码">
                        <el-input maxlength="20" placeholder="手机号码"
                                  v-model="formData.mobile"
                                  auto-complete="off" tabindex="6" type="text"></el-input>
                    </el-form-item>
                    <el-form-item prop="email" label="单位邮箱">
                        <el-input maxlength="100" placeholder="单位邮箱"
                                  v-model="formData.email"
                                  auto-complete="off" tabindex="7" type="text"></el-input>
                    </el-form-item>
                    <el-form-item prop="disabled" label="启用状态">
                        <el-switch
                                v-model="formData.disabled"
                                active-color="#ff4949"
                                inactive-color="#13ce66">
                        </el-switch>
                    </el-form-item>
                    <div class="flex flex-direction-row-reverse btn-group">
                        <el-button @click="addDialogVisible = false">取 消</el-button>
                        <el-button type="primary" @click="doAdd">确 定</el-button>
                    </div>
                </el-form>
            </template>
        </form-dialog>
        <form-dialog @close="handleClose('editForm')" title="编辑用户" :dialogVisible="editDialogVisible">
            <template slot="content">
                <el-form :model="formData" ref="editForm" :rules="editRules" size="small" label-width="80px">
                    <el-form-item prop="loginname" label="用户名">
                        {{formData.loginname}}
                    </el-form-item>
                    <el-form-item prop="username" label="姓名">
                        <el-input maxlength="20" placeholder="姓名"
                                  v-model="formData.username"
                                  auto-complete="off" tabindex="3" type="text"></el-input>
                    </el-form-item>
                    <el-form-item prop="siteid" label="关联店铺">
                        <el-select
                                class="inner-input"
                                v-model="formData.siteid"
                                filterable
                                remote
                                reserve-keyword
                                placeholder="请输入关联店铺名称"
                                :remote-method="remoteMethod"
                                :loading="loading">
                            <el-option
                                    v-for="item in storeOptions"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="mobile" label="手机号码">
                        <el-input maxlength="20" placeholder="手机号码"
                                  v-model="formData.mobile"
                                  auto-complete="off" tabindex="6" type="text"></el-input>
                    </el-form-item>
                    <el-form-item prop="email" label="单位邮箱">
                        <el-input maxlength="100" placeholder="单位邮箱"
                                  v-model="formData.email"
                                  auto-complete="off" tabindex="7" type="text"></el-input>
                    </el-form-item>
                    <el-form-item prop="disabled" label="启用状态">
                        <el-switch
                                v-model="formData.disabled"
                                active-color="#ff4949"
                                inactive-color="#13ce66">
                        </el-switch>
                    </el-form-item>
                    <div class="flex flex-direction-row-reverse btn-group">
                        <el-button @click="editDialogVisible = false">取 消</el-button>
                        <el-button type="primary" @click="doEdit">确 定</el-button>
                    </div>
                </el-form>
            </template>
        </form-dialog>
        <form-dialog @close="handleClose()" :title="roleDialogTitle" :dialogVisible="roleDialogVisible" width="60%">
            <template slot="content">
                <el-table border :data="roleTableData" style="width: 100%" size="small"
                          :highlight-current-row="true" row-key="id" lazy
                          :load="loadRoleChild">
                    <el-table-column label="菜单名称" prop="name"
                                     width="200" :show-overflow-tooltip="true" align="left">

                    </el-table-column>

                    <el-table-column label="URL" prop="href"
                                     :show-overflow-tooltip="true">
                    </el-table-column>

                    <el-table-column label="资源类型" prop="type"
                                     :show-overflow-tooltip="true" align="center">

                        <template slot-scope="scope">
                            <i v-if="scope.row.type=='menu'" style="font-weight: bold">菜单</i>
                            <i v-if="scope.row.type=='data'">数据</i>
                        </template>
                    </el-table-column>

                    <el-table-column label="权限标识" prop="permission"
                                     :show-overflow-tooltip="true">
                    </el-table-column>

                </el-table>
                <div class="flex flex-direction-row-reverse btn-group">
                    <el-button @click="roleDialogVisible = false">关 闭</el-button>
                </div>
            </template>
        </form-dialog>

    </el-container>

</template>

<script>
    import formDialog from '../../components/formDialog'
    import * as sysUser from '../../api/sysUser'
    import moment from 'moment'
    import {foodSiteData} from "../../api/storeArea";

    export default {
        name: "sysUser",
        components: {formDialog},
        data() {
            let validateLoginname = (rule, value, callback) => {
                let regex = new RegExp("^[a-zA-Z][a-zA-Z0-9_]{1,19}$");
                if (!regex.test(value)) {
                    callback(new Error('用户名以字母开头2-20字,允许使用字母/数字/下划线'));
                } else {
                    callback();
                }
            };
            let validatePass = (rule, value, callback) => {
                if (value !== this.formData.password) {
                    callback(new Error('两次输入密码不一致!'));
                } else {
                    callback();
                }
            };
            let validateUnit = (rule, value, callback) => {
                console.log(this.parentUnit)
                if (this.parentUnit.length < 1 || this.parentUnit[this.parentUnit.length - 1] == "" || this.parentUnit[this.parentUnit.length - 1] == "root") {
                    callback(new Error('请选择所属单位!'));
                } else {
                    callback();
                }
            };
            return {
                addDialogVisible: false,
                editDialogVisible: false,
                roleDialogVisible: false,
                treeData: {
                    label: 'name',
                    children: 'zones',
                    id: 'id',
                    isLeaf: 'leaf'
                },
                tableData: [],
                tableKey: "",
                options: [],
                parentUnit: [],
                parentUnitSearch: [],
                pageForm: {
                    searchUnit: "",
                    searchName: "",
                    searchKeyword: "",
                    pageNumber: 1,
                    pageSize: 10,
                    totalCount: 0,
                    pageOrderName: "",
                    pageOrderBy: ""
                },
                formData: {
                    id: "",
                    unitid: "",
                    disabled: false
                },
                formRules: {
                    parentUnit: [
                        {validator: validateUnit, trigger: ['blur', 'change']}
                    ],
                    loginname: [
                        {required: true, message: '用户名', trigger: ['blur', 'change']},
                        {validator: validateLoginname, trigger: ['blur', 'change']}
                    ],
                    username: [
                        {required: true, message: '姓名', trigger: ['blur', 'change']}
                    ],
                    email: [
                        {required: false, message: '邮箱地址', trigger: ['blur', 'change']},
                        {type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change']}
                    ],
                    mobile: [
                        {required: false, message: '手机号码', trigger: ['blur', 'change']},
                        {pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号码'}
                    ],
                    password: [
                        {required: true, message: '请输入密码', trigger: 'blur'},
                        {min: 3, max: 20, message: '密码长度必须是3-20位', trigger: 'change'}
                    ],
                    passwordAgain: [
                        {required: true, message: '请再次输入新密码', trigger: 'blur'},
                        {validator: validatePass, trigger: 'change'}
                    ]
                },
                editRules: {
                    username: [
                        {required: true, message: '姓名', trigger: ['blur', 'change']}
                    ],
                    email: [
                        {required: false, message: '邮箱地址', trigger: ['blur', 'change']},
                        {type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change']}
                    ],
                    mobile: [
                        {required: false, message: '手机号码', trigger: ['blur', 'change']},
                        {pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号码'}
                    ],
                },
                roleDialogTitle: "查看用户权限",
                roleTableData: [],//treeTable后台取出的数据
                userId: "",//当前用户ID
                multipleSelection: [],
                props: {
                    lazy: true,
                    checkStrictly: true,
                    multiple: false,
                    async lazyLoad(node, resolve) {
                        try {
                            let formdata = {pid: node.value}
                            let {data} = await sysUser.unitTree(formdata)
                            resolve(data)
                        } catch (e) {

                        }
                    }
                },
                loading: false,
                storeOptions: [],
            }
        },
        methods: {
            async remoteMethod(query) {
                if (query !== '') {
                    this.loading = true;
                    try {
                        let formData = {
                            searchName: 'name',
                            searchKeyword: query
                        }
                        let {data} = await foodSiteData(formData)
                        this.storeOptions = data.list
                    } catch (e) {

                    }
                    this.loading = false;
                } else {
                    this.storeOptions = [];
                }
            },
            handleClose(form) {
                if (form) {
                    this.$refs[form].resetFields();
                }
                this.addDialogVisible = false
                this.editDialogVisible = false
                this.roleDialogVisible = false

            },
            pageOrder(column) {//按字段排序
                this.pageForm.pageOrderName = column.prop;
                this.pageForm.pageOrderBy = column.order;
                this.pageData();
            },
            pageNumberChange(val) {//页码更新操作
                this.pageForm.pageNumber = val;
                this.pageData();
            },
            pageSizeChange(val) {//分页大小更新操作
                this.pageForm.pageSize = val;
                this.pageData();
            },
            async pageData() {//加载分页数据
                try {
                    let {data} = await sysUser.getSysUserData(this.pageForm)
                    this.tableData = data.list;
                    this.pageForm.totalCount = data.totalCount;
                } catch (e) {

                }
            },
            formatAt(val) {
                if (val > 0)
                    return moment(val ).format("YYYY-MM-DD HH:mm");
                return "";
            },
            doSearch() {
                let unit = this.parentUnitSearch[this.parentUnitSearch.length - 1];
                if ("root" == unit) {
                    unit = "";
                }
                this.pageForm.searchUnit = unit;
                this.pageForm.pageNumber = 1;
                this.pageData();
            },
            openAdd() {
                this.addDialogVisible = true;
                this.formData = {};//打开新增窗口,表单先清空
                if (this.$refs["addForm"])
                    this.$refs["addForm"].resetFields();
            },
            doAdd() {
                this.$refs["addForm"].validate(async (valid) => {
                    if (valid) {
                        this.formData.unitid = this.parentUnit[this.parentUnit.length - 1] || "";
                        try {
                            let data = await sysUser.userAddDo(this.formData)
                            this.$message({
                                message: data.msg,
                                type: 'success'
                            });
                            this.parentUnitSearch = [];
                            this.doSearch();//查询是带条件的,加载新建用户选择的单位用户数据
                            //this.pageData();
                            this.addDialogVisible = false;
                        } catch (e) {

                        }
                    }
                });
            },
            doEdit() {
                this.$refs["editForm"].validate(async (valid) => {
                    if (valid) {
                        try {
                            let data = await sysUser.userEditDo(this.formData)
                            this.$message({
                                message: data.msg,
                                type: 'success'
                            });
                            this.doSearch();//查询是带条件的,加载新建用户选择的单位用户数据
                            //this.pageData();
                            this.editDialogVisible = false;
                        } catch (e) {

                        }
                    }
                });
            },
            async initRoleTreeTable() {
                try {
                    let formdata = {pid: ""}
                    let {data} = await sysUser.userMenu(this.userId, formdata)
                    this.roleTableData = data;
                } catch (e) {

                }
            },
            async loadRoleChild(tree, treeNode, resolve) {
                try {
                    let formdata = {pid: tree.id}
                    let {data} = await sysUser.userMenu(this.userId, formdata)
                    resolve(data);
                } catch (e) {

                }
            },
            async dropdownCommand(command) {//监听下拉框事件
                if ("role" == command.type) {
                    this.roleDialogVisible = true;
                    this.roleDialogTitle = "查看用户 " + command.loginname + " 的权限";
                    this.userId = command.id;
                    this.initRoleTreeTable();
                }
                if ("reset" == command.type) {
                    try {
                        await this.$confirm('确定重置用户 ' + command.loginname + ' 的密码', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        })

                        let {data} = await sysUser.resetPwd(command.id)
                        this.$alert('新密码为 ' + data, '新密码', {confirmButtonText: '确定'});

                    } catch (e) {

                    }
                }
                if ("enable" == command.type || "disable" == command.type) {
                    try {
                        let data = await sysUser.userType(command.type, command.id)
                        this.$message({
                            message: data.msg,
                            type: 'success'
                        });
                        if ("disable" == command.type) {
                            command.row.disabled = true;
                        }
                        if ("enable" == command.type) {
                            command.row.disabled = false;
                        }
                    } catch (e) {

                    }
                }
                if ("edit" == command.type) {
                    try {
                        let {data} = await sysUser.userEdit(command.id)
                        let site = await foodSiteData()
                        this.storeOptions = site.data.list
                        this.formData = data;//加载后台表单数据
                        this.editDialogVisible = true;//打开编辑窗口
                    } catch (e) {

                    }
                }
                if ("delete" == command.type) {
                    try {
                        await this.$confirm('此操作将删除 ' + command.loginname, '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        })
                        let data = await sysUser.userDetel(command.id)
                        this.$message({
                            message: data.msg,
                            type: 'success'
                        });
                        this.doSearch();
                    } catch (e) {

                    }
                }
            },
            async batchCommand(command) {
                if ("delete" == command.type) {
                    if (this.multipleSelection.length == 0) {
                        this.$message({
                            message: "请选择要删除的用户",
                            type: 'warning'
                        });
                    } else {
                        let loginnames = [];
                        let ids = [];
                        this.multipleSelection.forEach(function (val) {
                            loginnames.push(val.loginname);
                            ids.push(val.id);
                        });
                        try {
                            await this.$confirm('此操作将删除用户 ' + loginnames.toString(), '提示', {
                                confirmButtonText: '确定',
                                cancelButtonText: '取消',
                                type: 'warning'
                            })

                            let formData = {
                                ids: ids.toString()
                            }
                            let data = await sysUser.usersDetel(formData)
                            this.$message({
                                message: data.msg,
                                type: 'success'
                            });
                            this.doSearch();
                        } catch (e) {

                        }
                    }
                }
                if ("export" == command.type) {
                    window.open(process.env.VUE_APP_BASEAPI + "/platform/sys/user/export?searchUnit=" + this.pageForm.searchUnit
                        + "&searchName=" + this.pageForm.searchName
                        + "&searchKeyword=" + this.pageForm.searchKeyword
                        + "&pageOrderName=" + this.pageForm.pageOrderName
                        + "&pageOrderBy=" + this.pageForm.pageOrderBy
                    );
                }
            },
            handleSelectionChange: function (val) {
                this.multipleSelection = val;
            }
        },
        created() {
            this.pageData();

        }
    }
</script>

<style lang="less">
    .el-table-expand {
        width: 100%;

    }

    .btn-group {
        button {
            margin-left: 20px;
        }

    }

    .el-input--prefix .el-input__inner {
        padding-left: 46px;
    }

    .el-input__prefix {
        left: 15px;
        color: #303133;
        i {
            font-style: normal;
        }
    }

    .icon-cursor {
        cursor: pointer;
        margin-right: 10px;
        &:hover {
            color: #348EED;
        }
    }

    .avatar-uploader {
        .el-upload {
            border: 1px solid #DCDFE6;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            width: 72px;
            display: block;
            &:hover {
                border-color: #409EFF;
            }
        }
        .cw-icon-tupian {
            font-size: 24px;
            color: #c0c4cc;
            width: 72px;
            height: 72px;
            line-height: 72px;
            text-align: center;
        }
        .avatar {
            width: 72px;
            height: 72px;
            display: block;
        }
    }

</style>
